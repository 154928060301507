var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _b;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount, useWeb3ModalProvider, } from "@web3modal/ethers/react";
import { BrowserProvider, parseUnits } from "ethers";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useDebouncedCallback } from "use-debounce";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// 1. Get projectId from https://cloud.walletconnect.com
var projectId = process.env.MIX_PROJECT_ID;
// 2. Set chains
var mainnet = await (await fetch("".concat(process.env.MIX_APP_URL, "/api/gateway")))
    .json()
    .then(function (response) {
    if (response.success) {
        var gateway = JSON.parse(response.data.gateway_parameter);
        return {
            chainId: Number(gateway.chain_id),
            name: gateway.name,
            currency: gateway.currency,
            explorerUrl: gateway.explorer_url,
            rpcUrl: gateway.rpc_url,
            adminWallet: gateway.admin_wallet,
        };
    }
    else {
        return {
            chainId: 651940,
            name: "ALL Mainnet",
            currency: "ALL",
            explorerUrl: "https://alltra.global",
            rpcUrl: "https://mainnet-rpc.alltra.global",
            adminWallet: '',
        };
    }
});
// 3. Create your application's metadata object
var metadata = {
    name: "Alltra FAST Payments Platform",
    description: "ALLTRA FAST: Financial Architecture for Smart Transactions. Secure Transactions, Simplified for Everyone the F.A.S.T. way",
    url: "".concat(process.env.MIX_APP_URL), // url must match your domain & subdomain
    icons: ["".concat(process.env.MIX_APP_URL, "/assets/images/default.png")],
};
// 4. Create Ethers config
var ethersConfig = defaultConfig({
    /*Required*/
    metadata: metadata,
    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: mainnet.rpcUrl, // used for the Coinbase SDK
    defaultChainId: mainnet.chainId, // used for the Coinbase SDK
});
// 5. Create a AppKit instance
createWeb3Modal({
    ethersConfig: ethersConfig,
    chains: [mainnet],
    projectId: projectId,
    enableAnalytics: false, // Optional - defaults to your Cloud configuration
    enableSwaps: false,
    enableOnramp: false,
    chainImages: (_b = {},
        _b[mainnet.chainId] = "/assets/images/logoIcon/logo _footer.png",
        _b),
});
export default function Wallet() {
    var _this = this;
    var _b = useWeb3ModalAccount(), address = _b.address, chainId = _b.chainId, isConnected = _b.isConnected;
    var walletProvider = useWeb3ModalProvider().walletProvider;
    var _j = useState(BigInt(0)), amount = _j[0], setAmount = _j[1];
    var _k = useState(BigInt(0)), balance = _k[0], setBalance = _k[1];
    var formAllMainnetPayment = useDebouncedCallback(
    // function
    function () { return __awaiter(_this, void 0, void 0, function () {
        var ethersProvider, signer, tx, receipt, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!isConnected) {
                        toast.error("Please connect wallet");
                        return [2 /*return*/, false];
                    }
                    if (!mainnet.adminWallet) {
                        toast.error("Admin wallet not found");
                        return [2 /*return*/, false];
                    }
                    if (amount >= balance) {
                        toast.error("The balance is not enough");
                        return [2 /*return*/, false];
                    }
                    if (!walletProvider) return [3 /*break*/, 6];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 5, , 6]);
                    $("#btn-allmainnet-payment").attr("disabled", "true");
                    ethersProvider = new BrowserProvider(walletProvider);
                    return [4 /*yield*/, ethersProvider.getSigner()];
                case 2:
                    signer = _b.sent();
                    return [4 /*yield*/, signer.sendTransaction({
                            to: mainnet.adminWallet,
                            value: amount,
                        })];
                case 3:
                    tx = _b.sent();
                    return [4 /*yield*/, tx.wait()];
                case 4:
                    receipt = _b.sent();
                    $('input[name="hash"]').val("".concat(receipt === null || receipt === void 0 ? void 0 : receipt.hash));
                    setTimeout(function () {
                        $("#btn-allmainnet-payment").removeAttr("disabled");
                        $("#allmainnet-payment").trigger("submit");
                    }, 3000);
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _b.sent();
                    $("#btn-allmainnet-payment").removeAttr("disabled");
                    console.error(e_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, 
    // delay in ms
    100);
    $("#btn-allmainnet-payment").on("click", function (e) {
        e.preventDefault();
        formAllMainnetPayment();
    });
    useEffect(function () {
        var getInfo = function () { return __awaiter(_this, void 0, void 0, function () {
            var ethersProvider, signer, bl_1, e_2, am;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!walletProvider) return [3 /*break*/, 6];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        ethersProvider = new BrowserProvider(walletProvider);
                        return [4 /*yield*/, ethersProvider.getSigner()];
                    case 2:
                        signer = _b.sent();
                        if (!address) return [3 /*break*/, 4];
                        return [4 /*yield*/, signer.provider.getBalance(address)];
                    case 3:
                        bl_1 = _b.sent();
                        setBalance(bl_1);
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_2 = _b.sent();
                        console.error(e_2);
                        return [3 /*break*/, 6];
                    case 6:
                        am = $('input[name="amountAll"]').val();
                        setAmount(parseUnits("".concat(am), 18));
                        return [2 /*return*/];
                }
            });
        }); };
        var timeoutId = setTimeout(getInfo, 100);
        return function () {
            clearTimeout(timeoutId);
        };
    }, [address, walletProvider]);
    return (_jsxs(_Fragment, { children: [_jsx("w3m-button", { balance: "hide" }), _jsx(ToastContainer, {})] }));
}
//@ts-ignore
var root = createRoot(document.getElementById("btn-wallet"));
root.render(_jsx(Wallet, {}));
